import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import { PopupButton } from '@typeform/embed-react'
import styles from './style.module.sass'

export default ({ comment }) => {
  if (!comment || comment.isHide) return null

  return (
    <div className={styles.comment}>
      <img {...srcSetProps(sanityImageUrl(comment.image))} className={styles.image} alt={comment.image?.caption} />
      <div className={styles.wrap}>
        <img
          {...srcSetProps(sanityImageUrl(comment.customerImage))}
          className={styles.customer}
          alt={comment.customerImage?.caption}
        />
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: comment.title }} />
        <ul className={styles.list}>
          {comment.benefits &&
            comment.benefits.map((item, index) => (
              <li className={styles.listItem} key={index}>
                {item}
              </li>
            ))}
        </ul>
        {comment.buttonUrl?.includes('https://quiz/') ? (
          <PopupButton
            id={buttonUrl.replace('https://quiz/', '')}
            as="a"
            transitiveSearchParams={true}
            className={styles.button}
          >
            {comment.buttonText}
          </PopupButton>
        ) : (
          <a href={comment.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
            {comment.buttonText}
          </a>
        )}
      </div>
    </div>
  )
}
